@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* You can add global styles to this file, and also import other style files */

@import "ngx-toastr/toastr";
@import "sweetalert2/src/sweetalert2.scss";

@import "./styles/components/index";

@import "@ng-select/ng-select/themes/default.theme.css";

@import "variables";

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.emoji-mart {
  bottom: 50px !important;
  right: 50px !important;
  position: absolute !important;
}

@import "bootstrap/scss/bootstrap";

a {
  text-decoration: none;
  color: #333333;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.ngx-pagination .current {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

pagination-template {
  ul {
    padding-left: 0 !important;
  }
}

pagination-template {
  ul {
    padding-left: 0 !important;
  }
}

.ngb-dp-header {
  background-color: #ffffff !important;
}

.ngb-dp-weekdays {
  background-color: #ffffff !important;
}

.pointer {
  cursor: pointer;
}

.calendar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-file-input {
  display: none !important;
}

.custom-file-label {
  font-size: 1em;
  padding: 6px 15px;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 6px;
}
