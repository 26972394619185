$mainColor: #66cc8a;

.loader {
  border-top-color: $mainColor;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

:root {
  --primary-color: $mainColor;
  --secondary-color: #555555;
  --success-color: #32d096;
  --info-color: #6cd4b7;
  --warning-color: #f4ef5c;
  --danger-color: #d22f2f;
  --light-color: #d6d6d6;
  --dark-color: #626262;
  --link-color: $mainColor;
  --link-hover-color: #333333;
  --grey-color: #626262;
}

$primaryColor: $mainColor;
$secondaryColor: #555555;
$success: #32d096;
$info: #6cd4b7;
$warning: #f4ef5c;
$danger: #d22f2f;
$light: #d6d6d6;
$dark: #626262;
$link-color: $mainColor;
$link-hover-color: #333333;
$grey: #626262;
$orange: #FFA500;

$theme-colors: (
  primary: $primaryColor,
  secondary: $secondaryColor,
  success: $success,
  warning: $warning,
  info: $info,
  danger: $danger,
  light: $light,
  dark: $dark,
  link-color: $link-color,
  link-hover-color: $link-hover-color,
  grey: $grey
);

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: $mainColor !important;
  border-color: $mainColor !important;
}

.btn-primary {
  color: #fff;
  background-color: $mainColor;
}

.bg-standard-green {
  background-color: $mainColor;
}

.bg-orange {
  background-color: $orange;
}

.text-standard-green {
  color: $mainColor;
}

.standard-shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 38, 0.2);
}

.placeholder-standard-grey::placeholder {
  color: #d6d6d6;
}

.standard-wrapper {
  background-color: white;
  border-radius: 8px;
}

.delete-button {
  background-color: #d22f2f;
  color: white;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  padding: 4px;
}

.edit-button {
  color: white;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  padding: 4px;
}

.search-button {
  color: white;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: $mainColor;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

$main900: #14532d;
$main800: #166534;
$main700: #1f8544;
$main600: #24a453;
$main500: #66cc8a;
$main400: #55e088;
$main300: #86efac;
$main200: #bbf7d0;
$main100: #dcfce7;

// Background colors

.bg-main-color-800 {
  background-color: $main800 !important;
}

.bg-main-color-700 {
  background-color: $main700 !important;
}

.bg-main-color-600 {
  background-color: $main600 !important;
}

.bg-main-color-500 {
  background-color: $main500 !important;
}

.bg-main-color-400 {
  background-color: $main400 !important;
}

.bg-main-color-300 {
  background-color: $main300 !important;
}

.bg-main-color-200 {
  background-color: $main200 !important;
}

.bg-main-color-100 {
  background-color: $main100 !important;
}

// Text colors

.text-main-color-900 {
  color: $main900 !important;
}

.text-main-color-800 {
  color: $main800 !important;
}

.text-main-color-700 {
  color: $main700 !important;
}

.text-main-color-600 {
  color: $main600 !important;
}

.text-main-color-500 {
  color: $main500 !important;
}

.text-main-color-400 {
  color: $main400 !important;
}

.text-main-color-300 {
  color: $main300 !important;
}

.text-main-color-200 {
  color: $main200 !important;
}

.text-main-color-100 {
  color: $main100 !important;
}

// Border colors

.border-main-color-600 {
  border-color: $main600 !important;

  :focus {
    color: $main600 !important;
  }
}

.border-main-color-500 {
  border-color: $main500 !important;

  :focus {
    border-color: $main500 !important;
  }
}

.border-main-color-400 {
  border-color: $main400 !important;

  :focus {
    border-color: $main400 !important;
  }
}
